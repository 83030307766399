<template>
  <div class="container flex-column">
    <div class="header p_sticky">
      <div class="wrap">
        <div class="title flex-row flex-center">
          <router-link to="/" class="home">
            <img :src="require('../assets/logo.jpg')" class="logo" />
            <span class="title-text">
              中医临床药学服务<br />与调剂教育平台
            </span>
          </router-link>
          <ul class="nav-menu flex-row justify-evenly flex1">
            <li
              v-for="(item, index) in links"
              :key="index"
              :class="{ active: item.active }"
            >
              <router-link
                :to="item.url"
                :class="{ active: item.url === '/knowledge' }"
                >{{ item.name }}</router-link
              >
            </li>
          </ul>
         
            <Avatar :ref="(el) => avatarRef = el" style="margin-right: 37px;" />
       
        </div>
      </div>
    </div>
    <div style="min-width: 1200px">
      <Search @loginStatus="loginStatus" />
    </div>
    <div class="know_tab">
      <div
        @click="tabClick(x + 1)"
        :class="`${tabKey === x + 1 ? 'tab_active' : ''}`"
        v-for="(i, x) in tabList"
        :key="x"
      >
        {{ i.name }}
      </div>
    </div>
    <div id="graphDom" style="min-width: 1200px">
      <div
        v-show="tabKey === 2"
        style="
          height: calc(100vh - 95px);
          margin: 10px;
          background-color: white;
        "
      >
        <KnowledgeGraph :resizetime="resizetime" />
      </div>
      <KnowledgeDirectory @loginStatus="loginStatus" v-show="tabKey === 1" />
    </div>
  </div>
</template>

<script >
import { reactive, toRefs, onMounted } from "vue";
import KnowledgeDirectory from "./KnowledgeDirectory.vue";
import { post, getLogindata } from "@/utils/common";
import { login } from "@/components/Login";
import router from "@/router";

export default {
  name: "Knowledge",
  components: {
    KnowledgeDirectory,
  },
  setup(props) {
    const state = reactive({
      avatarRef:'',
      tabKey: 1,
      tabList: [
        {
          id: 1,
          name: "知识库目录",
        },
        {
          id: 2,
          name: "知识图谱",
        },
      ],
      searchKeys: [
        {
          name: "中药",
        },
        {
          name: "中成药",
        },
        {
          name: "中药饮片",
        },
        {
          name: "贵细中药",
        },
      ],
      resizetime: null,
      // knowLedgeStatus: false
    });

    onMounted(() => {
      let isLogin = getLogindata() || {};
      if(!isLogin.headImg){
        state.avatarRef.LoginStatus(true);
      }
    });

    const tabClick = (tabKey) => {
      let isLogin = getLogindata() || {};
      if(!isLogin.headImg){
        console.log("aaaaaaa");
        login((logon) => {
          if (logon) {
            state.tabKey = tabKey;
            state.avatarRef.LoginStatus(false);
            setTimeout(() => {
              scrollToAnchor("graphDom");
              state.resizetime = Date.now();
            }, 20);
          }
        });
        return;
      }
      state.tabKey = tabKey;
      
      setTimeout(() => {
        scrollToAnchor("graphDom");
        state.resizetime = Date.now();
      }, 20);
    };

    const scrollToAnchor = (key) => {
      console.log("tab点击 ===>");
      if (key) {
        let anchorElement = document.getElementById(key);
        if (anchorElement) {
          anchorElement.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
      }
    };

    const loginStatus = () => {
      state.avatarRef.LoginStatus(false);
    }

    return {
      ...toRefs(state),
      tabClick,
      loginStatus
    };
  },
};
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  box-sizing: border-box;
  background: #ececec;
}

.header2 {
  padding: 0px;
  margin-top: 1rem;
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 0px 2px #f0f1f2;
}

.know_tab {
  // position: sticky;
  // top: 0;
  // left: 0;
  min-width: 1200px;
  display: flex;
  justify-content: center;
  font-size: 1.04vw;
  font-family: SimHei;
  font-weight: bold;
  color: #666;
  z-index: 999;
  background: #fff;
  border-bottom: 1px solid #e7e5e4;

  & > div {
    cursor: pointer;
    padding: 0.36vw 1.04vw;
    border-radius: 20px;
    background-color: rgba(255, 149, 82, 0);
    margin: 1.04vw 2.08vw 1.04vw 0px;
    transition: all 0.3s;
  }

  .tab_active {
    color: #fff;
    background-color: rgba(255, 149, 82, 1) !important;
  }
}

.search {
  width: 100%;
  position: absolute;
  bottom: 3vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .search_main {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .input_d {
      width: 40vw;
      height: 45px;
      border-radius: 20px;
      margin-bottom: 15px;
      overflow: hidden;

      input {
        width: 100%;
        height: 100%;
        padding-left: 30px;
        font-size: 18px;
      }

      input::-webkit-input-placeholder {
        color: #999;
        font-weight: bold;
      }
      input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #999;
        font-weight: bold;
      }
      input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #999;
        font-weight: bold;
      }
      input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #999;
        font-weight: bold;
      }
    }

    .search_keys {
      bottom: -20px;
      display: flex;
      font-size: 14px;
      font-family: SimHei;
      font-weight: bold;
      color: #666666;
      padding-left: 30px;

      .search_keey_item {
        margin-right: 20px;
      }
    }
  }
}
</style>
