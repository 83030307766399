<template>
  <div class="directory d-flex" style="flex-wrap: wrap">
    <div
      @click="() => toDetail(i)"
      class="card_item"
      v-for="(i, x) in mockData"
      :key="x"
    >
      <img :src="i.frontImg" />
    </div>
  </div>
</template>

<script >
import { reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
// import { post } from "@/utils/common";
import { post, getLogindata } from '@/utils/common';
import { login } from "@/components/Login";
export default {
  name: "KnowledgeDirectory",
  
  emits: ['loginStatus'],
  setup(props, context) {
    const router = useRouter();
    const state = reactive({
      mockData: [],
    });
    const load = () =>{
      post("WebKnowledgeBase/listKnowledgeBase", {}, (_data, res) => {
        console.log("data ===>", _data, res);
        const data = _data.data || {};
        state.mockData = data;
      });
    }
    onMounted(() => {
      // console.log('看看接受的多少 ',knowLedgeStatus.value);
      console.log('ss啥东西',props,context  );
      load();
      let isLogin = getLogindata() || {};
      if(!isLogin.headImg){
        login((logon) => {
          if (logon){
          context.emit('loginStatus');
          load();
        }
      })
      return;
    }
    
    });

    const toDetail = ({ id }) => {
      let isLogin = getLogindata() || {};
      if(!isLogin.headImg){
      login((logon) => {
        if (logon){
          router.push({ path: "/know_detail", query: { knowledgeBaseId: id } });
        }
      })
      return;
    }
      router.push({ path: "/know_detail", query: { knowledgeBaseId: id } });
    };

    return {
      ...toRefs(state),
      toDetail,
    };
  },
};
</script>

<style lang="less" scoped>
.directory {
  box-sizing: border-box;
  background: #fff;
  padding: 20px 1%;
}

.card_item {
  width: 23%;
  height: 12vw;
  margin: 0px 1%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 2%;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
